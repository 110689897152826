import React from "react";
import Container from "../../../components/container";
import OneQuestionForm from "../../../components/OneQuestionForm";
import { navigate } from "gatsby";
export default function Borracho() {
  return (
    <Container>
      <section className="flex-col flex items-center md:justify-between mt-8 mb-16 md:mb-12">
        <h1 className="text-6xl md:text-8xl font-bold tracking-tighter leading-tight md:pr-8 text-center">
          Enigma del borracho.
        </h1>
        <img
          className="mt-5 rounded-2xl "
          src="/borracho.jpg"
          title="Borracho"
          alt="Borracho"
        />
        <OneQuestionForm
          question=" Un borracho dijo: Si ayer fuese mañana, hoy sería viernes. ¿En qué día
          de la semana el borracho dijo esto?"
          validateResponse={(input) => {
            let normalizedInput = input
              .normalize("NFKD")
              .replace(/[\u0300-\u036f]/g, "");
            return normalizedInput.toLowerCase().includes("domingo");
          }}
          onSuccess={() => navigate("/taza1/acertijo/suma")}
        />
      </section>
    </Container>
  );
}
